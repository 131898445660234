// Core colors

$white: #fff;
$black: #000;
$light-grey: #d9d9d9;
$mid-grey: #999;
$semi-dark-grey: #666;
$dark-grey: #333;
$passive-grey: #f2f2f2;

$divider: #dfdfdf;

// Brand Colours

$primary-colour: #7494a4;
$secondary-colour: #b4ccd4;
$accent-colour: #f49c7d;
$nav-header-colour: #222;

$container-bg-faded: tint-color($secondary-colour, 20%);

$primary: $primary-colour;
$secondary: $secondary-colour;

// Set Bootstrap colours
$custom-colors: (
  "primary": $primary-colour,
  "secondary": $primary-colour,
  "accent": $accent-colour,
);

// Fonts
$primary-font: "Quicksand", sans-serif;
$secondary-font: "Raleway", sans-serif;
$tertiary-font: "Lobster Two", cursive;

// Container variables
$container-max-width: 1200px;
$container-padding: 1rem;

$zindex: (
  carousel-controls: 10,
  sticky-cta: 20,
  banner-content: 20,
  banner-treatment: 10,
  mobile-nav: 40,
  mobile-header: 40,
  mobile-nav-overlay: 30,
  homepage-image-text-block: 10,
);
