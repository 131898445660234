.header__main {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 0.5rem 0;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.header__logo-container {
  align-items: center;
  display: flex;
  max-width: 110px;

  @include media-breakpoint-up(md) {
    max-width: 130px;
  }

  .header__site-logo {
    width: 100%;
    max-width: 110px;

    @include media-breakpoint-up(md) {
      min-width: 130px;
    }
  }
}

.header__nav-container {
  display: flex;
  align-items: center;
}
