@include media-breakpoint-up(md) {
  .gallery__grid {
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;

    .gallery__image {
      height: 22.5rem;
      flex: 1 0 auto;
      margin: 0 0.75rem 0.75rem 0;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.15);
        opacity: 0;
        transition: opacity 0.15s ease;
        pointer-events: none;
        z-index: 2;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }

      picture {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .gallery__grid {
    .gallery__image {
      pointer-events: none;
      padding-left: 0 !important; // override inline style set for tablet/desktop masonry style layout
      margin-bottom: 1rem;

      img {
        width: 100%;
      }
    }
  }
}
