.card {
  position: relative;

  &:hover {
    img {
      transform: scale(1.025);
    }

    .card__title {
      span {
        border-color: $primary-colour;
      }
    }
  }
}

.card__content-wrapper {
  max-width: 90%;

  @include media-breakpoint-up(lg) {
    max-width: 80%;
  }
}

.card__title {
  @include text-xx-large;

  color: $primary-colour;

  @include media-breakpoint-up(lg) {
    @include text-x-large;
  }

  span {
    display: inline-block;
    border-bottom: solid 2px transparent;
  }

  i {
    font-weight: 400;
    margin-left: 0.25rem;
  }
}

.card__image {
  display: block;
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  img {
    transform: scale(1);
    transition: transform 0.2s ease;
    position: absolute;
    top: 0;
    left: 0;
  }
}
