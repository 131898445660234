label {
  cursor: pointer;
}

legend {
  float: none;
}

.form-control {
  border-radius: 0;
}
