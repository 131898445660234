@use "sass:map";

@import "~tiny-slider/src/tiny-slider";

$nav-height: 0.25rem;
$nav-bottom-offset: 0.75rem;
$nav-padding: 0.5rem;

// TNS
.tns-outer {
  position: relative;
}

.tns-slider {
  margin-bottom: 0;
}

.tns-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: map.get($zindex, carousel-controls);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  padding: 0 1.5rem;

  button {
    pointer-events: auto;
    opacity: 0.75;
    transition: opacity 0.25s;
    color: $white;

    &:hover {
      opacity: 1;
    }
  }
}

.tns-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: $nav-bottom-offset;
  left: 0;
  right: 0;
  margin-bottom: 0;
  z-index: map.get($zindex, carousel-controls);

  button {
    opacity: 0.75;
    transition: opacity 0.25s;
    z-index: map.get($zindex, carousel-controls);
    padding: $nav-padding 0.25rem;

    &::before {
      content: "";
      display: block;
      width: 1rem;
      height: $nav-height;
      background: $white;
    }

    &:hover,
    &.tns-nav-active {
      opacity: 1;
    }
  }
}

// Carousel wrapper
.carousel__wrapper {
  position: relative;
}
