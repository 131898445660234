@use "sass:map";

header {
  position: relative;
  background-color: $white;
  border-bottom: solid 1px #f3f3f3;

  @include media-breakpoint-down(lg) {
    z-index: map.get($zindex, mobile-header);
  }
}

.header__nav {
  @include media-breakpoint-down(lg) {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    right: 0;
    background-color: $white;
    z-index: map.get($zindex, mobile-nav);
    overflow-y: scroll;
    max-height: 100vh;
  }
}

.header__nav-list {
  font-family: $secondary-font;

  @include text-x-small;

  @include media-breakpoint-up(lg) {
    &.collapse {
      display: block;
    }
  }

  .header__nav-item {
    display: block;
    position: relative;
    border-top: solid 1px $divider;

    &:first-of-type {
      border-top: none;
    }

    @include media-breakpoint-up(lg) {
      display: inline-block;
      border-top: none;
      padding: 0 0.75rem;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      &:hover {
        .header__subnav {
          display: block;
        }
      }
    }

    a {
      &:hover,
      &.current-page,
      .active & {
        color: $accent-colour;
        text-decoration: none;
      }
    }

    > a {
      position: relative;
      padding: 0.5rem 1rem;
      display: block;
      text-decoration: none;
      color: $nav-header-colour;

      @include media-breakpoint-up(lg) {
        color: inherit;
        padding: 1.5rem 0;
        display: inline-block;

        &::before {
          content: "";
          position: absolute;
          bottom: 1.15rem;
          left: 0;
          right: 0;
          background-color: transparent;
          height: 3px;
        }

        &:hover,
        &.current-page,
        .active & {
          &::before {
            background-color: $accent-colour;
          }
        }
      }
    }

    &.has-subnav {
      position: relative;

      @include media-breakpoint-down(lg) {
        &.active {
          &::after {
            transform: rotate(180deg);
          }

          .header__subnav {
            display: block;
          }
        }

        a {
          padding-right: 2rem;
        }

        &::after {
          font: var(--fa-font-solid);
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          content: "\f078";
          position: absolute;
          right: 1rem;
          top: 0.75rem;
          transition: all 0.25s ease;
          color: $accent-colour;
          pointer-events: none;
        }
      }
    }
  }
}

.header__subnav {
  display: none;

  @include media-breakpoint-down(xl) {
    padding-bottom: 0.5rem;
  }

  .header__subnav-item {
    a {
      display: block;

      @include text-xx-small;

      padding: 0.25rem 0;
      line-height: 1.2;
      color: $nav-header-colour;

      @include media-breakpoint-down(lg) {
        @include text-x-small;

        padding-left: 2rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    background-color: $primary-colour;
    z-index: 2;
    padding: 0.5rem;

    .header__subnav-item {
      display: inline-block;
      margin: 0 0.5rem;

      a {
        color: $white;
        border-bottom: solid 1px transparent;

        &::before {
          bottom: 0.25rem;
          height: 1px;
        }

        &:hover,
        &.current-page {
          color: $white;
          border-color: $white;

          &::before {
            background-color: $white;
          }
        }
      }
    }
  }
}

.navbar-toggler {
  font-size: 1.25rem;
  width: 1.25rem;

  &:focus {
    box-shadow: none;
  }

  &.collapsed {
    &::after {
      content: "\f0c9"; // fa-bars
      font: var(--fa-font-solid);
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
    }
  }

  &::after {
    content: "\f00d"; // fa-times
    font: var(--fa-font-solid);
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
}

.nav-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: map.get($zindex, mobile-nav-overlay);
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity 0.15s ease;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
}
