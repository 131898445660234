//
// Reset buttons to resemble links instead
//

button {
  -webkit-appearance: inherit;
  -webkit-box-align: inherit;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline;
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  border-radius: 0;
  overflow: visible;
}
