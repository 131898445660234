html {
  scroll-behavior: smooth;
  font-size: 18px;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

body {
  font-family: $primary-font;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: $container-max-width;
  }

  &--narrow {
    @include media-breakpoint-up(md) {
      max-width: 85%;
    }

    @include media-breakpoint-up(lg) {
      width: 70%;
      max-width: 56.25rem;
    }
  }

  &--x-narrow {
    @include media-breakpoint-up(md) {
      max-width: 75%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 65%;
    }
  }

  &--xx-narrow {
    @include media-breakpoint-up(md) {
      max-width: 65%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }

    @include media-breakpoint-up(xl) {
      max-width: 40%;
    }
  }
}

.bg-faded {
  background-color: $container-bg-faded;
}
