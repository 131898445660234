.footer {
  background-color: $primary-colour;
  padding: 1.5rem 0 1rem;
  font-size: 0.9rem;
  color: $white;

  @include media-breakpoint-up(md) {
    padding: 3rem 0 1rem;
    font-size: 0.75rem;
  }

  a {
    color: $white;
  }
}

.footer__map-address {
  &.content {
    a {
      @include arrow-link($white);

      padding-right: 1rem;
    }
  }

  p {
    margin-bottom: 0.5rem;
  }
}

.footer__nav-list {
  columns: 2;

  @include media-breakpoint-up(md) {
    padding-right: 2rem;
  }
}

.footer__nav-item {
  margin-bottom: 0.35rem;
}

.footer__social-accounts {
  font-size: 1.15rem;

  a {
    margin-right: 0.5rem;
    text-decoration: none;

    &:hover {
      opacity: 0.75;
    }
  }
}

.footer__subscribe-form-text {
  p {
    font-size: 1rem;
  }
}

.footer__subscribe-form {
  font-size: 0.75rem;

  .form-control {
    font-size: 0.85rem;
  }
}

.footer__site-logo {
  max-width: 160px;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    max-width: 70%;
  }
}

.footer__utility-links {
  font-size: 0.65rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: right;
  }

  li:not(:last-of-type) {
    border-right: solid 1px rgba($white, 0.5);
    padding-right: 0.5rem;
    margin-right: 0.5rem;
  }
}
