@use "sass:map";

.content {
  // Spacing

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0 1rem;
  }

  p,
  ul,
  ol,
  table,
  a {
    margin: 1rem 0;
  }

  img {
    margin: 1.5rem 0;
    max-width: 100%;
    height: auto;
  }

  iframe {
    max-width: 100%;
  }

  // Remove spacing from first and last elements

  & > *:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 0;
  }

  blockquote {
    position: relative;
    font-style: italic;

    @include text-large;

    &::before {
      content: "\201C";
      display: block;
      font-size: 6rem;
      top: 0.5rem;
      left: -0.75rem;
      position: relative;
      line-height: 1rem;
      color: $accent-colour;
      font-family: $tertiary-font;
    }
  }
}

.content__text-block {
  padding: 2rem 1.5rem;

  @include media-breakpoint-up(md) {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @include media-breakpoint-up(xl) {
    padding: 2rem 2.5rem;
  }
}

.content__full-width-block {
  min-height: 600px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    min-height: 750px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.45);
  }

  .content__text-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 3rem;
    color: #fff;

    @include media-breakpoint-down(md) {
      &.content {
        padding: 1.5rem 3rem;
      }
    }
  }

  .template__homepage & {
    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      margin: -2rem 0;
    }

    @include media-breakpoint-up(lg) {
      margin: -3rem 0;
    }
  }
}

.content--accent-links {
  a:not(.btn):not(.arrow-link) {
    color: $accent-colour;
    border-bottom: solid 1px $accent-colour;

    &:hover {
      text-decoration: none;
      border-color: transparent;
    }
  }
}

.content--padded-text {
  .content {
    padding: 1.5rem 0;
  }
}

@include media-breakpoint-up(md) {
  .content__image-text-block--even {
    .col__image {
      order: 1;
    }
  }
}

body:not(.template__homepage) {
  .content__blocks-wrapper {
    > * {
      margin-bottom: 3rem;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .content__image-text-block {
    + .content__image-text-block {
      margin-top: 2rem;

      @include media-breakpoint-up(md) {
        margin-top: 4rem;
      }
    }
  }
}

.content__image-text-block {
  overflow: hidden;

  .template__homepage & {
    position: relative;
    z-index: map.get($zindex, homepage-image-text-block);
  }
}

// Style anchors as buttons
.content--anchor-buttons {
  a {
    @extend .btn, .btn-accent;

    text-decoration: none;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}
