@use "sass:map";

@mixin arrow-link($colour: $primary-colour) {
  display: inline-block;
  color: $colour;
  text-decoration: none;
  border-bottom: solid 1px transparent;
  padding-right: 1.25rem;
  position: relative;

  &:hover {
    text-decoration: none;
    border-color: $colour;
  }

  &::after {
    content: "\f061"; // arrow right
    font: var(--fa-font-solid);
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: $colour;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: all 0.25s ease;
  }

  &--underlined {
    border-color: inherit;

    &:hover {
      border-color: transparent;
    }
  }

  &--animate-arrow {
    &:hover {
      &::after {
        right: -0.5rem;
      }
    }
  }
}

.arrow-link {
  @include arrow-link;
}

.btn-cta {
  cursor: pointer;
  color: $white;
  margin: auto 0.25rem auto auto;

  &:hover {
    color: $white;
  }

  @include media-breakpoint-up(md) {
    padding: 0.5rem 0.75rem;
  }

  &--sticky {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    bottom: 1.25rem;
    left: 1rem;
    right: 1rem;
    z-index: map.get($zindex, sticky-cta);
    transition: opacity 0.25s ease;

    &--active {
      pointer-events: initial;
      display: block;
      opacity: 1;
    }
  }
}

// Bootstrap overrides

.btn-primary {
  color: $white;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}

.btn-accent {
  &:not(:disabled):not(.disabled):active {
    color: $white;
  }
}
