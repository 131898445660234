.form-group {
  margin-bottom: 1rem;
}

.formie-form__wrapper {
  .fui-field-container {
    @extend .form-group;
  }

  .fui-row {
    margin-bottom: 1.5rem;

    > * {
      margin-bottom: 1rem;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .fui-label {
    @extend .form-label;

    @include text-small;
  }

  .fui-input:not(.fui-checkbox-input):not(.fui-radio-input) {
    @extend .form-control;

    border-color: #dfdfdf;
    border-bottom: solid 3px #dfdfdf;

    &:focus {
      box-shadow: none;
      border-bottom: solid 3px $accent-colour;
    }
  }

  .fui-select {
    @extend .form-select;

    overflow-x: hidden;
  }

  .fui-legend {
    @include text-small;
  }

  .fui-checkbox {
    @extend .form-check;

    .fui-checkbox-input {
      @extend .form-check-input;
    }

    .fui-checkbox-label {
      @extend .form-check-label;
    }
  }

  .fui-radio {
    @extend .form-check;

    .fui-radio-input {
      @extend .form-check-input;
    }

    .fui-radio-label {
      @extend .form-check-label;
    }
  }

  .fui-submit {
    @extend .btn, .btn-accent;

    color: $white;
    width: 100%;
    margin: 1rem 0;
    font-weight: 500;

    &:hover {
      color: $white;
    }
  }

  textarea.fui-input {
    min-height: 7rem;
    resize: vertical;
  }

  .fui-required {
    color: $danger;
  }

  .fui-instructions {
    margin-top: 0.5rem;

    @include text-small;

    font-style: italic;
  }

  .fui-alert {
    @extend .alert;

    @include text-small;
  }

  .fui-alert-error {
    @extend .alert-danger;
  }

  .fui-error-message {
    color: $danger;

    @include text-xx-small;

    margin-top: 0.25rem;
  }
}

.grecaptcha-badge {
  // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
  visibility: hidden;
  pointer-events: none;
}
