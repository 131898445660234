//
// Root
//

body {
  line-height: 1.5;
}

a {
  text-decoration: none;
}

//
// Small text
//

@mixin text-small {
  font-size: 0.9375rem;
}

@mixin text-x-small {
  font-size: 0.875rem;
}

@mixin text-xx-small {
  font-size: 0.8125rem;
}

@mixin text-xxx-small {
  font-size: 0.75rem;
}

.text-small {
  @include text-small;
}

.text-x-small {
  @include text-x-small;
}

.text-xx-small {
  @include text-xx-small;
}

.text-xxx-small {
  @include text-xxx-small;
}

//
// Large text
//

@mixin text-large {
  font-size: 1.125rem;
}

@mixin text-x-large {
  font-size: 1.25rem;
}

@mixin text-xx-large {
  font-size: 1.5rem;
}

.text-large {
  @include text-large;
}

.text-x-large {
  @include text-x-large;
}

.text-xx-large {
  @include text-xx-large;
}

//
// Headings
//

@mixin heading-shared {
  font-weight: 600;
  font-family: $secondary-font;
  line-height: 1.25;
  transition: font-size 0.25s;
}

@mixin heading-one {
  @include heading-shared;

  font-size: 1.9rem;

  @include media-breakpoint-up(sm) {
    font-size: 2.2rem;
  }
}

@mixin heading-two {
  @include heading-shared;

  font-size: 1.75rem;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
}

@mixin heading-three {
  @include heading-shared;

  font-size: 1.6rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }
}

@mixin heading-four {
  @include heading-shared;

  font-size: 1.45rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.6rem;
  }
}

@mixin heading-five {
  @include heading-shared;

  font-size: 1.3rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.4rem;
  }
}

@mixin heading-six {
  @include heading-shared;

  font-size: 1.15rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.2rem;
  }
}

h1,
.h1 {
  @include heading-one;
}

h2,
.h2 {
  @include heading-two;
}

h3,
.h3 {
  @include heading-three;
}

h4,
.h4 {
  @include heading-four;
}

h5,
.h5 {
  @include heading-five;
}

h6,
.h6 {
  @include heading-six;
}

.script-typeface {
  font-family: $tertiary-font;
  font-weight: 400;
  font-style: italic;
}

.page-heading {
  font-family: $tertiary-font;
  font-weight: 400;
  font-size: 2.5rem;
  font-style: italic;
  letter-spacing: 1px;

  @include media-breakpoint-up(sm) {
    font-size: 3rem;
    margin-top: 1.5rem;
  }
}

//
// Horizontal rules
//

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid $divider;
}

//
// Emphasis
//

small,
.small {
  @include text-small;

  font-weight: 400;
}

mark,
.mark {
  padding: 0.25rem;
  background-color: $primary;
}

//
// Lists
//

.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
}

.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}
