body:not(.no-js) {
  .animate__fadeUp {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.35s ease;

    &.animate--animated {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animate__fade {
    opacity: 0;
    transition: opacity 0.5s ease;

    &.animate--animated {
      opacity: 1;
    }
  }

  .animate__fadeInLtoR {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 0.5s ease;

    &.animate--animated {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .animate__fadeInRtoL {
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.5s ease;

    &.animate--animated {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.animate__delay--025 {
  transition-delay: 0.25s;
}

.animate__delay--05 {
  transition-delay: 0.5s;
}

.animate__delay--1 {
  transition-delay: 1s;
}

.animate__delay--2 {
  transition-delay: 2s;
}
