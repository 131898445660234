@use "sass:map";

$banner-mobile-breakpoint: 600px;

.banner {
  color: #fff;
}

// Banner treatments

.banner--darken-treatment {
  content: "";
  background: rgba(0, 0, 0, 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  pointer-events: none;
  z-index: map.get($zindex, banner-treatment);
}

// Banner heights

.banner__image {
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;

  // Desktop
  @include aspect-ratio(600, 1600);

  // Tablet
  @media screen and (max-width: 1000px) {
    @include aspect-ratio(600, 1000);
  }

  // Mobile
  @media screen and (max-width: $banner-mobile-breakpoint) {
    @include aspect-ratio(700, 600);
  }

  img,
  video,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

// Banner videos

.banner__video {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    @media screen and (min-width: $banner-mobile-breakpoint) {
      display: none;
    }
  }

  video {
    @media screen and (max-width: $banner-mobile-breakpoint) {
      display: none;
    }
  }
}

// Banner content

.banner__content-positioner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: map.get($zindex, banner-content);
}

.banner__content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $container-padding;
  margin: 0 auto;
  max-width: $container-max-width;

  .banner--multiple & {
    // Accounts for carousel navigation
    padding-bottom: ($nav-bottom-offset * 2) + $nav-height;
  }

  @media screen and (min-width: $banner-mobile-breakpoint) {
    &--desktop-left {
      justify-content: flex-start;
    }

    &--desktop-center {
      text-align: center;
      justify-content: center;
    }

    &--desktop-right {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: $banner-mobile-breakpoint) {
    text-align: center;

    &--mobile-bottom {
      align-items: flex-end;
    }

    &--mobile-center {
      align-items: center;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &--mobile-top {
      align-items: flex-start;
    }
  }
}

.banner__content {
  max-width: 540px;

  *:last-child {
    margin-bottom: 0;
  }
}
